import React, { useState } from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import Layout from "../components/layout/fr"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ToShare from "../components/restaurant-menu/to-share"
import Starters from "../components/restaurant-menu/starters"
import Carpaccios from "../components/restaurant-menu/carpaccios"
import PastaAndRices from "../components/restaurant-menu/pasta-and-rices"
import SeaFood from "../components/restaurant-menu/sea-food"
import Fish from "../components/restaurant-menu/fish"
import Meet from "../components/restaurant-menu/meet"
import Beefs from "../components/restaurant-menu/beefs"
import Desserts from "../components/restaurant-menu/desserts"

const CartaPage = (props) => {
	const [menuCategory, setMenuCategory] = useState('to-share');
	const seoDescription = "Plats à parteger, entrées froides, carpaccios et tartares, fruits de mer, poisson, viandes, bœufs et Veaux, et desserts";
	let SelectedCategory;
	if (menuCategory === 'to-share') {
		SelectedCategory = ToShare;
	} else if (menuCategory === 'starters') {
		SelectedCategory = Starters;
	} else if (menuCategory === 'carpaccios') {
		SelectedCategory = Carpaccios;
	} else if (menuCategory === 'pasta-and-rices') {
		SelectedCategory = PastaAndRices;
	} else if (menuCategory === 'sea-food') {
		SelectedCategory = SeaFood;
	} else if (menuCategory === 'meet') {
		SelectedCategory = Meet;
	} else if (menuCategory === 'fish') {
		SelectedCategory = Fish;
	} else if (menuCategory === 'beefs') {
		SelectedCategory = Beefs;
	} else if (menuCategory === 'desserts') {
		SelectedCategory = Desserts;
	} 

	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="La carte" 
	    	lang="fr"
	    	description={seoDescription}
	    />
	    <HeroImage 
	      titleText="La carte"
	      image="carta" 
	    />
	    <MenuSection className="menu">
	      <MenuHeaders className="menu-headers">
	        <li onClick={() => setMenuCategory('to-share')}>
	        	Pour partager
	        </li>
	        <li onClick={() => setMenuCategory('starters')}>
	        	Entrées Froides
	        </li>
	        <li onClick={() => setMenuCategory('carpaccios')}>
	        	Carpaccios et tartares
	        </li>
	        <li onClick={() => setMenuCategory('pasta-and-rices')}>
	        	Pâtes et riz
	        </li>
	        <li onClick={() => setMenuCategory('sea-food')}>
	        	Fruits de mer
	        </li>
	        <li onClick={() => setMenuCategory('fish')}>
	        	Poisson
	        </li>
	        <li onClick={() => setMenuCategory('meet')}>
	        	Viandes
	        </li>
	        <li onClick={() => setMenuCategory('beefs')}>
	        	Bœufs et Veaux
	        </li>
	        <li onClick={() => setMenuCategory('desserts')}>
	        	Desserts
	        </li>
	        <Link to="/fr/menu">
	        	<h2>Menu en semaine</h2>
	        </Link>
	      </MenuHeaders>
	      <MobileView>
		      <SelectBox value={menuCategory} onChange={(event) => setMenuCategory(event.target.value)}>
		        <option value="to-share">Pour partager</option>
		        <option value="starters">Entrées Froides</option>
		        <option value="carpaccios">Carpaccios et tartares</option>
		        <option value="pasta-and-rices">Pâtes et riz</option>
		        <option value="sea-food">Fruits de mer</option>
		        <option value="fish">Poisson</option>
		        <option value="meet">Viandes</option>
		        <option value="beefs">Bœufs et Veaux</option>
		        <option value="desserts">Desserts</option>
		      </SelectBox>
		      <Link to="/fr/menu">
		      	<span>Menu en semaine</span>
		      </Link>
		    </MobileView>
	      <SelectedCategory lang='fr'/>
	    </MenuSection>
	  </Layout>
	)
}

const MenuSection = styled.div`
	margin-top: 140px;
	display: flex;
	@media (max-width: 540px){
		margin-top: 100px;
	  flex-direction: column;
	}
`

const SelectBox = styled.select`
	font-size: 16px;
	margin-right: 10px;
`

const MobileView = styled.div`
	@media (min-width: 540px){
	  display: none;
	}
`

const MenuHeaders = styled.div`
	display: flex;
	flex-direction: column;
	list-style-type: none;
	width: 250px;
	li {
	  cursor: pointer;
	  padding: 10px;
	  line-height: 20px;
	}
	@media (max-width: 540px){
	  display: none;
	}
`

export default CartaPage